import React from 'react'
import NavBar from './NavBar'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './Footer'

const Container= styled.div`
    
`
const SharedLayout = () => {
  return (
    <Container>
        <NavBar></NavBar>
        <Outlet/>
        <Footer></Footer>
    </Container>
  )
}

export default SharedLayout