import React, { useState } from 'react'
import styled from 'styled-components'
import TopText from './TopText'
import {BsHospital} from 'react-icons/bs'
import {FaClinicMedical, FaHandshake} from 'react-icons/fa'
import {mobile} from './Responsive'
const Container= styled.div`
    padding: 60px;
    ${mobile({padding: "20px"})}
`
const Header= styled.h2`
    
`
const ServiceWrapper= styled.div`
    margin: 30px;
`
const Service= styled.div`
    display: flex;
    ${mobile({flexDirection: "column"})}
`
const ServiceImageArea=styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`
const ServiceImage= styled.img`
    max-height: 300px;
    max-width: 400px;
`
const ServiceHeader= styled.h1`
    
`
const Icon=styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #01FFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: absolute;
    z-index: 1;
`
const serviceDescription=styled.text``
const List= styled.ul`
    
`
const ListItem= styled.li`
    
`
const HospitalIcon=styled(BsHospital)`
    color: white;
    width: 70px;
    height: 70px;
`
const ClinicIcon=styled(FaClinicMedical)`
    color: white;
    width: 70px;
    height: 70px;
`
const HandShakeIcon=styled(FaHandshake)`
    color: white;
    width: 70px;
    height: 70px;
`
const Services = () => {
    const [text, setText]=useState("We provide a wide range of professional healthcare services, that are tailor-made for all Our client needs in a conducive environment. This equips each client with what they need to succeed in recovery and helps bring positive change in their lives and those of their families. Our caring team of professionals and support staff do what it takes to help minimize or slow down the disabling effects of chronic health conditions and help individuals adapt to, and where possible, recover from impairment or incapacity for service or work as a result of physical injury or psychological status. The sole objective is to retain the same social, vocational and educational status, as he or she had before the injury or disease. Our record of success is underpinned by research into the health benefits for the good work we do to our customers.")
  return (
    <Container>
        <Header>Our Services</Header>
        <TopText headerText={text}/>
        <ServiceWrapper>
        <ServiceHeader>Treatment and Rehabilitation</ServiceHeader>
            <Service>
                <ServiceImageArea>
                    <ServiceImage src="assets/imgs/residentialroom2.jpg"/>
                    <Icon>
                        <HospitalIcon/>
                    </Icon>
                </ServiceImageArea>
                <serviceDescription>
                    <List>
                        <ListItem>90 day residential treatment</ListItem>
                        <ListItem>6 weeks residential treatment</ListItem>
                        <ListItem>Group Therapy</ListItem>
                        <ListItem>Individual Therapy</ListItem>
                        <ListItem>Family Therapy</ListItem>
                    </List>
                </serviceDescription>
            </Service>
            <ServiceHeader>Outpatient Services</ServiceHeader>
            <Service>
                <ServiceImageArea>
                    <ServiceImage src="assets/imgs/residentialroom2.jpg"/>
                    <Icon>
                        <ClinicIcon/>
                    </Icon>
                </ServiceImageArea>
                <serviceDescription>
                    <List>
                        <ListItem>After-Care services</ListItem>
                        <ListItem>Psychiatric Review</ListItem>
                        <ListItem>Screening & Assesment </ListItem>
                        <ListItem>Detoxification</ListItem>
                        <ListItem>Addiction Counseling</ListItem>
                    </List>
                </serviceDescription>
            </Service>
            <ServiceHeader>AfterCare Services</ServiceHeader>
            <Service>
                <ServiceImageArea>
                    <ServiceImage src="assets/imgs/tv.jpg"/>
                    <Icon>
                        <HandShakeIcon/>
                    </Icon>
                </ServiceImageArea>
                <serviceDescription>
                    <p>This program is offered free of charge and runs once a week for a period of 3 months after the initial Residential Treatment.</p>
                </serviceDescription>
            </Service>
        </ServiceWrapper>
    </Container>
  )
}

export default Services