import React from 'react'
import styled from 'styled-components'
import { useState, useEffect , useRef} from 'react'
import Slide from './Slide'
import { keyframes } from 'styled-components'
import ServicesCard from './ServicesCard'
import {mobile} from './Responsive' 
import AboutUs from './AboutUs'
import Services from './Services'
import Partners from './Partners'
import Faq from './Faq'
import Contacts from './Contacts'
import FacebookPosts from './FacebookPosts'
import { FaWhatsapp} from 'react-icons/fa'
import Youtube from './Youtube'
import {AiOutlineCloseCircle, AiFillYoutube, AiFillForward, AiFillBackward} from 'react-icons/ai'
const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
`
const Container= styled.div`
    `
const ImageSlide=styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    ${mobile({height: "40vh" })}
 `
 const Image= styled.img`
    max-width: 100%;
 `
 const NextImageIcon= styled(AiFillForward)`
 border-radius: 50%;
 width:30px;
 height: 30px;
 color: #01B7EA;
`
const PreviousImageIcon= styled(AiFillBackward)`
border-radius: 50%;
width:30px;
height: 30px;
color: #01B7EA;
`
const NextImageButton= styled.button`
border-radius: 50%;
width:50px;
height: 50px;
position: absolute;
right:2%;
cursor: pointer;

`
const PreviousImageButton= styled.button`
border-radius: 50%;
width:50px;
height: 50px;
position: absolute;
left:2%;
cursor: pointer;

`
 const SlideText = styled.div`
    position: absolute;
    bottom: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: white;
    font-size: 2rem;
    font-weight: bold;
`;
 const Wrapper= styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0px 5%;
 `
 const MissionContainer= styled.div`
    display: flex;
    justify-content: center;
    ${mobile({display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center"})}
 `
 const Mission= styled.div`
    background-color: #000080;
    flex: 1;
    padding-left: 20px;
  
 `
 const Vision= styled.div`
     background-color: #01B7EA;
     flex: 1;
     padding-left: 20px;

 `
 const MissionVisionHeader= styled.h1`
  color: white;
 `
 const MissionVisionText= styled.p`
  color: white;
 `
const WhatsAppUs= styled.div`
  display: flex;
  background-color: #25d366;
  height: 400px;
  padding: 0px 20px;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  position: fixed;
  right: 0;
  ${mobile({height: "50px" , bottom: "0"})}
`
const YoutubeContainer= styled.div`
display: flex;
flex-direction: column;
height: 800px;
padding: 0px 20px;
cursor: pointer;
position: fixed;
left: 0;
z-index: 1000;
${mobile({display:"none"})}
`
const YoutubeVideoWrapper= styled.div`
display: ${props=>props.display};
`
const YoutubeCloseIcon= styled(AiOutlineCloseCircle)`
  border-radius: 50%;
  width:30px;
  height: 30px;
  color: red;

`
const YoutubeOpenIcon= styled(AiFillYoutube)`
  border-radius: 50%;
  width:50px;
  height: 50px;
  color: red;
`
const YoutubeCloseButton= styled.div`
  border-radius: 50%;
  width:30px;
  height: 30px;
`
const Link=styled.a`
    text-decoration: none;
    color: white;
`

const Home = () => {
    const [images, setImages] = useState([
        {src:'assets/imgs/caretech_landing_image.jpg', text:"Our dinning room"},
        {src:'assets/imgs/caretech_gate.jpg', text:"Our dinning room"},
        {src:'assets/imgs/caretech_compound.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_reception.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_waiting.jpg',text:"Our rehabilitation center offers mental health services with a highly qualified staff specializing in addiction treatment, counselling, and inpatient treatment. Our team of experienced psychiatrists and psychologists provides services for managing mental disorders such as depression, PTSD, and alcohol use disorder. We also provide support for relapse prevention and aftercare."},
        {src:'assets/imgs/caretech_boardroom.jpg', text:"Our dinning room"},
        {src:'assets/imgs/caretech_tv.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_canteen.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_ward_bed.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_hostel.jpg',text:"our lobby"},
        {src:'assets/imgs/caretech_beds.jpg',text:"our lobby"},

      ]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const[display, setDisplay]=useState("flex")
    const NextImage=()=>{

      setCurrentSlide(currentSlide => (currentSlide + 1) % images.length);
    }
    const PreviousImage=()=>{
      if(currentSlide!=0){
        setCurrentSlide(currentSlide => (currentSlide - 1) % images.length);
      }
      else{
        setCurrentSlide(currentSlide => (images.length - 1) % images.length);
      }
    }
      
    const ref = useRef(null);

  useEffect(() => {
    console.log(ref)
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [currentSlide]);

  const changeYoutubeDisplay=()=>{

    if(display=='flex'){
      setDisplay("none")
    }
  else{
    setDisplay("flex")
  }
  }


  return (
    <Container>
      <ImageSlide>
        <Image src={images[currentSlide].src} alt={`Slide ${currentSlide}`} />
        {/* <SlideText>{images[currentSlide].text}</SlideText> */}
        <NextImageButton onClick={NextImage}>
          <NextImageIcon/>
        </NextImageButton>
        <PreviousImageButton onClick={PreviousImage}>
          <PreviousImageIcon/>
        </PreviousImageButton>
      </ImageSlide>

        <Wrapper>
        <MissionContainer>
          <Mission>
            <MissionVisionHeader>Our Mission</MissionVisionHeader>
            <MissionVisionText>To be an influential healthcare provider offering teaching, research, and referral facilities worldwide.</MissionVisionText>

          </Mission>
          <Vision>
          <MissionVisionHeader>Vision</MissionVisionHeader>
            <MissionVisionText>We envisioned to provide the highest quality of medical and therapeutic services to all sorrounding communities as well as training opportunities for all helathcare workers</MissionVisionText>
          </Vision>
          <Mission>
          <MissionVisionHeader>Our Goal</MissionVisionHeader>
            <MissionVisionText>To guide persons battling with addiction/mental health-related disorders and thier families into creating a recovery base that is most suited for their unique therapeutic needs.</MissionVisionText>
          </Mission>
        </MissionContainer>
        <WhatsAppUs>
        <Link href="https://api.whatsapp.com/send?phone=254720419440" target="_blank" rel="noreferrer">
        <FaWhatsapp/>
        Chat With a proffessional Today
        </Link>
        </WhatsAppUs>
        <YoutubeContainer >
          <YoutubeCloseButton  onClick={changeYoutubeDisplay} >
            <YoutubeCloseIcon display={display}/>
          </YoutubeCloseButton >
          <YoutubeVideoWrapper display={display}>
            <Youtube />
          </YoutubeVideoWrapper>
          <YoutubeOpenIcon onClick={changeYoutubeDisplay}/>
        </YoutubeContainer>
        </Wrapper>
        <AboutUs ref={ref}/>
        <Services id="services"/>
        <Partners/>
        <Faq/>
        <FacebookPosts/>
        <Contacts/>
    </Container>
  )
}

export default Home