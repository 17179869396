import React from 'react'
import styled from 'styled-components' 
import  { keyframes } from 'styled-components';
import {mobile} from './Responsive' 


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container= styled.div`
 
`
const Header= styled.h2`
    
`
const StaffWrapper= styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    flex-direction: column;
    ${mobile({padding: "2%"})}

`
const StaffImageWrapper= styled.div`
    display: flex;
    ${mobile({flexDirection: "column"})}
`
const StaffImage= styled.img`
    max-height: 300px;
    max-width: 300px;
`
const StaffText= styled.text`
    padding: 20px;
`
const StaffPosition= styled.text`
    font-weight: 800;
    color: #1974D2;
    font-size: 40px;
    animation: ${fadeIn} 1s ease-in-out;
`
const StaffName= styled.text`

    font-weight: 800;
    font-size: 28px;
    animation: ${fadeIn} 1s ease-in-out;

`

const Staff = () => {
  return (
    <Container>
        <Header>Meet Our Staff</Header>
        <StaffWrapper>
        <StaffPosition>Managing Director</StaffPosition>
        <StaffName>Jane Mbugua</StaffName>
        <StaffImageWrapper>
            <StaffText>
            Jane is a social worker by profession with a BA in Social Work and a Masters in Public Health, both in Anglia Ruskin University in Cambridge, UK. During and after her education in the UK, she worked and volunteered in healthcare services for over ten years. Her passion for family and the tragic loss of her brother to alcoholism led her to set up a recovery facility that would address the plight of drug related illnesses, including addictions to alcohol and other substances, as well as offer quality education and awareness of these illnesses. Central to her priorities is to blur the disparities in the society as well as sensitise the communities about substance use disorder.
            </StaffText>
                <StaffImage src="assets/imgs/director.jpg"/>
            </StaffImageWrapper>
        </StaffWrapper>
        <StaffWrapper>
        <StaffPosition>Centre Administrator</StaffPosition>
        <StaffName>Peter Sankale</StaffName>
        <StaffImageWrapper>
            <StaffText>
            Caretech was established with the aim of providing quality and affordable healthcare to

all her clients. This is the reason Caretech has put up state-of-the-art structures in place

to support healthcare service delivery.

The leadership of Caretech Medical & Rehabilitation Centre is executed through hospital administrators, patient care managers and practice managers. They typically manage an entire facility, medical practice group of physicians, specific clinical areas and departments such as finance, materials management or patient care services.

The board of Directors offer the overall leadership of this organization and make final

decisions on all matters policy and direction.

</StaffText>
                <StaffImage src="assets/imgs/administrator.jpg"/>
                
            </StaffImageWrapper>
        </StaffWrapper>
        
    </Container>
      )
}

export default Staff