import React, {useState} from 'react'
import styled, { keyframes } from 'styled-components'
import {FaFacebook, FaTwitter, FaWhatsapp, FaYoutube} from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import {mobile} from './Responsive'

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container= styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 100;
`
const Wrapper= styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    ${mobile({display: "none"})}
`
const MenuItems= styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const MenuItem= styled(NavLink)`
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: blue;
    font-weight: 600;
    font-size: 18px;
`
const Logo= styled.img`
    height: 60px;
`
const SocialMediaLinks= styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const SocialIcon= styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
`
const WhatsAppUs= styled.div`
    animation: ${blink} 6s infinite;
    animation-delay: 3s; /* Delay for 3 seconds before starting the animation */
    background-color: #25d366;
    height: 100%;
    padding: 0px 20px;
    cursor: pointer;
    justify-content: flex-end;
    align-content: flex-end;
`

const Link=styled.a`
    text-decoration: none;
    color: white;
    justify-content: center;
    align-content: center;
`
const BlogLink=styled.a`
   padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: blue;
    font-weight: 600;
    font-size: 18px;
`
const MobileWrapper=styled.div`
display: none;
${mobile({marginTop:"850px",display:"flex",flexDirection: "column",alignItems: "start", zIndex: "1000", position: "absolute", backgroundColor: "white", height: "100vh", width: "100%"})}
`
const MobileMenuItem= styled(NavLink)`
    font-size:20px;
    font-weight: 700;
    cursor:pointer;
    margin:15px 25px;
    text-decoration: none;
    color: blue;
`
const OpenLinksButton=styled.button`
    width: 70px;
    height: 50px;
    background: none;
    border: none;
    color: teal;
    font-size: 30px;
    cursor: pointer;
    display: none;
    ${mobile({display: "block"})}
`
const MobileSocialLinks= styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:15px 25px;
    position: fixed;
    bottom: 0;
`
const NavBar = () => {
    const [mobileNavbar, setMobileNavbar]= useState(false)
  return (
    <Container>
        <Wrapper>
        <Logo src="assets/imgs/logo.jpg" alt="Slide" />
        <MenuItems>
            <MenuItem to ="/">Home</MenuItem>
            <MenuItem to ="/aboutus">About Us</MenuItem>
            <MenuItem to ="/services">Services</MenuItem>
            <MenuItem to ="/partners">Our Partners</MenuItem>
            <MenuItem to ="/contactus">Contact Us</MenuItem>
            <MenuItem to ="/faq">FAQs</MenuItem>
            <BlogLink href="https://caretechmedicalrehabilitationcentrekiambu.wordpress.com/" target="_blank" rel="noreferrer">
            BLOG
            </BlogLink>        
        </MenuItems>
        
        <SocialMediaLinks>
            <SocialIcon color="3B5999">
                <Link href="https://www.facebook.com/CaretechMedical" target="_blank" rel="noreferrer">
                    <FaFacebook/>
                </Link>
            </SocialIcon>
            <SocialIcon color="55ACEE">
                <Link href="https://twitter.com/CaretechL" target="_blank" rel="noreferrer">
                    <FaTwitter/>
                </Link>
            </SocialIcon>
            
            <SocialIcon color="25d366">
                <Link href="https://api.whatsapp.com/send?phone=254720419440" target="_blank" rel="noreferrer">
                    <FaWhatsapp/>
                </Link>
            </SocialIcon>
            <SocialIcon color="FF0000">
                <Link href="https://www.youtube.com/@caretechmedicallimited6467" target="_blank" rel="noreferrer">
                    <FaYoutube/>
                </Link>
            </SocialIcon>
        </SocialMediaLinks>
        </Wrapper>
        <OpenLinksButton onClick={()=>{setMobileNavbar((curr)=>!curr)}}>{!mobileNavbar? '☰':  'X' }</OpenLinksButton>
        {mobileNavbar && (
          <MobileWrapper>
        <MobileMenuItem to ="/" onClick={()=>{setMobileNavbar((curr)=>!curr)}}>
          HOME
        </MobileMenuItem>
        <MobileMenuItem to ="/aboutus" onClick={()=>{setMobileNavbar((curr)=>!curr)}}>
          ABOUT US
        </MobileMenuItem>
        <MobileMenuItem to ="/services" onClick={()=>{setMobileNavbar((curr)=>!curr)}}> SERVICES</MobileMenuItem>
        <MobileMenuItem to ="/partners" onClick={()=>{setMobileNavbar((curr)=>!curr)}}> PARTNERS</MobileMenuItem>
        <MobileMenuItem to ="/contactus" onClick={()=>{setMobileNavbar((curr)=>!curr)}}> CONTACT US</MobileMenuItem>
        <MobileMenuItem to ="/faq" onClick={()=>{setMobileNavbar((curr)=>!curr)}}>FAQs</MobileMenuItem>
        <BlogLink href="https://caretechmedicalrehabilitationcentrekiambu.wordpress.com/" target="_blank" rel="noreferrer"></BlogLink>
        <MobileSocialLinks>
        <SocialIcon color="3B5999">
                <Link href="https://www.facebook.com/CaretechMedical" target="_blank" rel="noreferrer">
                    <FaFacebook/>
                </Link>
            </SocialIcon>
            <SocialIcon color="55ACEE">
                <Link href="https://twitter.com/CaretechL" target="_blank" rel="noreferrer">
                    <FaTwitter/>
                </Link>
            </SocialIcon>
            
            <SocialIcon color="25d366">
                <Link href="https://api.whatsapp.com/send?phone=254720419440" target="_blank" rel="noreferrer">
                    <FaWhatsapp/>
                </Link>
            </SocialIcon>
            <SocialIcon color="FF0000">
                <Link href="https://www.youtube.com/@caretechmedicallimited6467" target="_blank" rel="noreferrer">
                    <FaYoutube/>
                </Link>
            </SocialIcon>
        </MobileSocialLinks>
        </MobileWrapper>
        )}
    </Container>
  )
}

export default NavBar