import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import {mobile} from './Responsive' 
import { NavLink } from 'react-router-dom'


const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
`
const Container= styled.div`
    margin-top: 30px;
    max-width: 100vw;
    /* background-color: #DD1F13; */
`
const PartnersWrapper= styled.div`
    display: flex;
    overflow: hidden;
    ${mobile({display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center"})}
`
const Partner=styled.div`
    padding: 30px;
`
const PartnerImage= styled.img`
    width: 200px;
    max-height: 100px;
`
const LinksContainer= styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mobile({display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center"})}
`
const Copyright= styled.text`
    color: white;

`
const MenuItems= styled.div`
    ${mobile({display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center"})}
`
const MenuItem= styled(NavLink)`
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
`
const BlogLink=styled.a`
   padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 15px;
`

const Footer = () => {
  return (
    <Container>
        <PartnersWrapper>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/aar.png"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/jubilee.png"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/zamara.png"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/nhif.jpg"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/minet.jpg"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/mua.png"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/coop.jpg"/>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/Insurances/apa.png"/>
            </Partner>
        </PartnersWrapper>
        <LinksContainer>
          <MenuItems>
            <MenuItem to ="/">Home</MenuItem>
            <MenuItem to ="/aboutus">About Us</MenuItem>
            <MenuItem to ="/services">Services</MenuItem>
            <MenuItem to ="/partners">Our Partners</MenuItem>
            <MenuItem to ="/contactus">Contact Us</MenuItem>
            <MenuItem to ="/faq">FAQs</MenuItem>
            <BlogLink href="https://caretechmedicalrehabilitationcentrekiambu.wordpress.com/" target="_blank" rel="noreferrer">
            BLOG
            </BlogLink>        
        </MenuItems>
        <Copyright>Caretech Medical {new Date().getFullYear()}. All Rights Reserved. </Copyright>
        </LinksContainer>
    </Container>
  )
}

export default Footer