import React , {forwardRef} from 'react'
import styled from 'styled-components'
import {FaLightbulb, FaHandshake, FaHands} from 'react-icons/fa'
import {BsFillBagHeartFill} from 'react-icons/bs'
import {GiFruitTree} from 'react-icons/gi'
import Staff from './Staff'
import {mobile} from './Responsive'
import Youtube from './Youtube'

const Container= styled.div`
    padding: 60px;
    display: flex;
    flex-direction: column;
    ${mobile({padding: "20px"})}

`
const Header= styled.h2`
    
`
const AboutUsTextWrapper= styled.div`
    background-color: #01B7EA;
    padding: 20px;
    ${mobile({padding: "20px"})}
`
const AboutUsText= styled.p`
    color: white;
    font-weight: 700px;
    font-size: 18px;
`
const OurValuesHeader= styled.div`
        margin-top: 20px;
`
const OurValuesWrapper= styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: space-between;
    flex-wrap: wrap;
`
const Value= styled.div`
    flex-basis: 33%;
    display: flex;
    align-items: center;
    padding-top: 30px;
`
const ValueIcon= styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #01FFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`
const BulbIcon=styled(FaLightbulb)`
     color: white;
    width: 90px;
    height: 90px;
`
const HeartIcon=styled(BsFillBagHeartFill)`
     color: white;
    width: 90px;
    height: 90px;
`
const HandShakeIcon=styled(FaHandshake)`
     color: white;
    width: 90px;
    height: 90px;
`
const HandsIcon=styled(FaHands)`
     color: white;
    width: 90px;
    height: 90px;
`
const TreeIcon=styled(GiFruitTree)`
     color: white;
    width: 90px;
    height: 90px;
`
const OurValue= styled.p`
    
`

const AboutUs = ({targetRef}) => {
  return (
    <Container ref={targetRef}>
        <Header>About Us:</Header>
        <AboutUsTextWrapper>
            <AboutUsText >Caretech is located along Kiambu Road, Kugeria opposite Edenville Estate in a serene and conducive environment. We seek to deliver timely, integrated, effective and efficient healthcare which is responsive to the needs of the local communities. Our proximity enable us to shift services closer to people's homes.

We provide a spectrum of preventative and primary healthcare through a diverse employee skill. Our healthcare services are designed to meet your everyday healthcare needs. Our centre offers exemplary value based medicare through highly qualified doctors, nurses and medical technicians forming a strong foundation for reliable healthcare services.

Quality management is the basis on which Caretech services and business operations are conducted. We recognise the importance of providing high quality assured service to our clients, and extend the same policy to our suppliers and consultants. They apply the same principles in their operations when providing products or services to us.

Care Tech was set up to fill a growing demand for quality healthcare, Alcohol and Drug Abuse, Relapse Rate intervention, and all the psychological challenges affecting the growth and development of human personality in East and Central Africa at large.</AboutUsText>
        </AboutUsTextWrapper>
        <Youtube/>
        <Staff></Staff>
        <OurValuesHeader>
                <h3>OUR VALUES:</h3>
            </OurValuesHeader>
        <OurValuesWrapper>
            <Value>
                <ValueIcon>
                    <BulbIcon/>
                </ValueIcon>
                <OurValue>HOPE</OurValue>
            </Value>
            <Value>
                <ValueIcon>
                    <HeartIcon/>
                </ValueIcon>
                <OurValue>COMPASSION</OurValue>
            </Value>
            <Value>
                <ValueIcon>
                    <HandShakeIcon/>
                </ValueIcon>
                <OurValue>RESPECT</OurValue>
            </Value>
            <Value>
                <ValueIcon>
                    <HandsIcon/>
                </ValueIcon>
                <OurValue>DEPENDABILITY</OurValue>
            </Value>
            <Value>
                <ValueIcon>
                    <TreeIcon/>
                </ValueIcon>
                <OurValue>PERSEVERANCE</OurValue>
            </Value>
        </OurValuesWrapper>
    </Container>
  )
}

export default forwardRef(AboutUs)