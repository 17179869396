import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import validator from 'validator'

const Container= styled.div`
    display:flex;
    flex-direction: column;
`

const Title= styled.h1`
    font-size: 24px;
    font-weight: 300;
`

const Form= styled.form`
    display: flex;
    flex-direction: column;

`
const Textinput= styled.input`
    flex:1;
    min-width: 40%;
    padding: 10px;
    margin: 10px  0px;
    border: ${props => props.password ? "none" : "1 px solid black"};
    &:focus {
        outline: none;
    }
`
const TextArea= styled.textarea`
    flex:1;
    min-width: 40%;
    padding: 10px;
    margin: 10px  0px;
    border: ${props => props.password ? "none" : "1 px solid black"};
    &:focus {
        outline: none;
    }
`
const Button= styled.button`
    width: 40%;
    border: none;
    padding: 15px 20px;
    background-color: #01B7EA;
    color: white;
    cursor: pointer;
 `
 const Text= styled.text`
    color: red;
`

const Query = () => {
    const [emailTo, setEmailTo]= useState("")
    const [body, setBody]= useState("")
    const [phone, setPhone]=useState("+254")
    const [errorText, setErrorText]= useState("")
    const navigate=useNavigate()

    const submitForm=(emailTo, body )=>{
        if(validator.isEmail(emailTo) && body!="" && phone.length>10){
            const email=(`${body} <br></br> <br></br> To reply to the enquiry use the user details below <br></br> <a href = "mailto: ${emailTo}">${emailTo}</a> <br></br> <a href="tel:${phone}">${phone}</a>`)
            axios.post(`http://34.204.9.148/emailsender/api/email/sendemail`, {
                emailTo: emailTo,
                subject: "New Enquiry",
                body: email,
                from: "",
                status:"New",
                senderName: "CareTech"
              })
              .then(function (response) {
                setErrorText("Your query has been submitted successfully. Caretech will get back to you. ")
              })
              .catch(function (error) {
                console.log(error);
                setErrorText(error.message)
              });
        }
        else{
            setErrorText("ERROR!!Enter the right details")
        }
        
      }
    
  return (
    <Container>
        <Title>Make an Enquiry Now:</Title>
            <Form onSubmit={e => {
              setErrorText("submitting...")
            e.preventDefault();
            submitForm( emailTo, body );
        }}>
                <Textinput placeholder="your email" value={emailTo} onChange={(event)=>setEmailTo(event.target.value)}/>
                <Textinput placeholder="phone" value={phone} onChange={(event)=>setPhone(event.target.value)}/>
                <TextArea placeholder="your query" value={body} onChange={(event)=>setBody(event.target.value)}/>

                <Button>SUBMIT</Button>
                <Text>{errorText}</Text>
            </Form>
    </Container>
  )
}

export default Query