import React from 'react'
import styled from 'styled-components'
import TopText from './TopText'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {MdOutlineAlternateEmail} from 'react-icons/md'
import { FaWhatsapp} from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import {mobile} from './Responsive' 
import Query from './Query'


const Container= styled.div`
    padding: 60px;
    ${mobile({padding: "10px", marginTop: "50px"})}
`
const Header= styled.h2`
    
`
const ContactsWrapper= styled.div`
    display: flex;
    justify-content: space-between;
    ${mobile({display: "flex", flexDirection: "column", justifyContent:"flex-Start", alignItems: "flexStart"})}
`
const TelephoneWrapper=styled.div`
    margin-top: 30px;
    display: flex;
    flex-basis: 30%;
    align-items: center;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    ${mobile({display: "flex", })}
`
const TelephoneIcon= styled(BsFillTelephoneFill)`
    color: white;
    width: 50px;
    height: 50px;
`
const EmailIcon= styled(MdOutlineAlternateEmail)`
    color: white;
    width: 50px;
    height: 50px;
`
const WhatsappIcon= styled(FaWhatsapp)`
    color: white;
    width: 50px;
    height: 50px;
`
const Telephone= styled.text`
    color: black;
    font-weight: 700;
    overflow-wrap: break-word;
    overflow: ellipsis;
`
const ValueIcon= styled.div`
    width: 120px;
    height: 120px;
    background-color: #${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    ${mobile({width:"70px", height: "70px"})}
`
const Link=styled.a`
    text-decoration: none;
    color: white;
    justify-content: center;
    align-content: center;
`
const Contacts = () => {
  return (
    <Container>
        <Header>We Would Love to Hear From You:</Header>
    <TopText fontSize="28px" headerText="Caretech is located along Kiambu Road, Kugeria opposite Edenville Estate"></TopText>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.9588184138324!2d36.83785557314226!3d-1.1893023987993288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f3db8e0a7cc71%3A0x60201fe7389583ad!2sCaretech%20Medical%20Limited!5e0!3m2!1sen!2ske!4v1683309731181!5m2!1sen!2ske" width="100%" height="450" style={{border:0, allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
    <Header>Click the links below:</Header>
    <ContactsWrapper>
    <Link href="tel:+254720419499">
        <TelephoneWrapper>
                <ValueIcon color="1554cb">
                    <TelephoneIcon/>
                </ValueIcon>
            <Telephone>+254720419499</Telephone>
        </TelephoneWrapper>
        </Link>
        <Link href="mailto:info@caretechmedical.com" target="_blank" rel="noreferrer">
            <TelephoneWrapper>
                    <ValueIcon color="EA4335"> 
                        <EmailIcon/>
                    </ValueIcon>
                <Telephone>info@caretechmedical.com</Telephone>
            </TelephoneWrapper>
        </Link>
        <Link href="https://api.whatsapp.com/send?phone=254720419499" target="_blank" rel="noreferrer">
            <TelephoneWrapper>
                    <ValueIcon color="25d366">
                        <WhatsappIcon/>
                    </ValueIcon>
                <Telephone>+254720419499</Telephone>
            </TelephoneWrapper>
        </Link>
    </ContactsWrapper>
    <Query/>
    </Container>
  )
}

export default Contacts