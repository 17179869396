import { createGlobalStyle } from 'styled-components';
import Bitter from '../Fonts/Bitter/Bitter-VariableFont_wght.ttf';


const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'Bitter';
    src: url(${Bitter});
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'Bitter', serif;
    line-height: 2.0;
  }
  `;

export default GlobalStyle;