import { forwardRef } from 'react'
import React from 'react'
import styled from 'styled-components'
import {mobile} from './Responsive'

const TopTextWrapper= styled.div`
    background-color: #01B7EA;
    padding: 20px;
    ${mobile({padding: "20px"})}
`
const Text= styled.p`
    color: white;
    font-weight: 700px;
    font-size: 18px;
`

const TopText = ({headerText}, ref) => {
  return (
    <TopTextWrapper>
        <Text>
            {headerText}
        </Text>
    </TopTextWrapper>
  )
}

export default forwardRef(TopText)