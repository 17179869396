import React from 'react'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import {mobile} from './Responsive' 


const Container=styled.div`
    padding: 2%;
`

const Youtube = () => {
    const opts = {
        height: '400',
        width: '100%',
        playerVars: {
          autoplay: 0, // Change to 1 if you want the video to autoplay
        },
        
      };
  return (
    <Container>
        <YouTube videoId="ii56cBxpK-w" opts={opts} />
    </Container>
  )
}

export default Youtube