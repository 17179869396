import React from 'react'
import styled from 'styled-components';


const Container = styled.div`
border: 1px solid black;

`;
const StyledSlide = styled.div`
`;
const Image = styled.img`
  height: 400px;
  width: 100pw;
  
`;

const Slide = ({ image }) => {
  return (
    <Container>
      <Image src={image} alt="Slide" />
    </Container>

  )
}

export default Slide