import React from 'react'
import styled from 'styled-components'
import {mobile} from './Responsive' 


const Container= styled.div`
    align-items: center;
`
const Header= styled.h2`
    padding-left: 90px;
`
const PartnersWrapper= styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
`
const Partner= styled.div`
    display: flex;
    flex-basis: 20%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    margin-left: 5%;
    ${mobile({display: "flex", flexBasis: "40%"})}
`
const PartnerImage= styled.img`
    margin-top: 5%;
    height: 100px;
    max-width: 100px;
`
const PartnerName= styled.h3`
    font-size: 14px;
`

const Partners = () => {
  return (
    <Container>
        <Header>Our Partners</Header>
        <PartnersWrapper>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kiambu.png"/>
                <PartnerName>Kiambu County</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/makueni.jpeg"/>
                <PartnerName>Makueni County</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kra.jpg"/>
                <PartnerName>Kenya Revenue Authority</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/cak.jpg"/>
                <PartnerName>Communications Authority of Kenya</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/police.jpg"/>
                <PartnerName>Kenya Police Service</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/prisons.jpg"/>
                <PartnerName>Kenya Prisons Service</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kenyapipeline.png"/>
                <PartnerName>Kenya Pipeline Ltd</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/portland.jpg"/>
                <PartnerName>East African Portland Cement</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kuralogo.png"/>
                <PartnerName>Kenya Urban Roads Authority</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kws.png"/>
                <PartnerName>Kenya Wildlife Service</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/kplc.png"/>
                <PartnerName>Kenya Power and Lighting Company</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/ira.png"/>
                <PartnerName>Insurance Regulatory Authority</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/airways.png"/>
                <PartnerName>Kenya Airways</PartnerName>
            </Partner>
            <Partner>
                <PartnerImage src="assets/imgs/partners/medicalresearch.svg"/>
                <PartnerName>Kenya Medical Research Institute</PartnerName>
            </Partner>
        </PartnersWrapper>
        

    </Container>
  )
}

export default Partners