import React from 'react'
import styled from 'styled-components'
import {mobile} from './Responsive' 

const Container= styled.div`
    padding: 90px;
    ${mobile({padding: "20px"})}
`
const Header= styled.h2`
    
`
const QuestionWrapper= styled.div`
    display: flex;
    flex-basis: 20%;
    flex-direction: column;
    box-shadow: 0 0 5px 2px #1974D2;
    padding-left: 5%;
    margin: 10px;
    margin-bottom: 10px;
`
const Question= styled.text`
    font-size: 30px;
`
const Response=styled.text`

`
const Faq = () => {
  return (
    <Container>
        <Header>Frequently Asked Questions:</Header>
        <QuestionWrapper>
            <Question>What is Addiction?</Question>
            <Response>Addiction is disease like any other that's treatable and manageable.</Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>How long does treatment for addiction takes?</Question>
            <Response>It depends  with one drug of choice / battling with i.e For Alcohol takes 90 days( Months) while Narcotics 180 days( 6 months).</Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>What services do you offer?</Question>
            <Response>We offer both Inpatient and outpatient integrated programs but no limited to:<br></br>
                <ul>
                    <li>Psychiatric Review</li>
                    <li>Screening & Assesment</li>
                    <li>Detoxification</li>
                    <li>Addiction Counseling</li>
                    <li>Group Therapy</li>
                    <li>Aftercare Programs</li>
                    <li>Family Therapy</li>
                    <li>Life Skill Prevention</li>
                    <li>Relapse Prevention Program</li>
                </ul>
            </Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>Are there facilities for both genders?</Question>
            <Response>Yes, we do not discriminate on gender in that we admit  both  male and female.

We have different quarters for male/ female clients.</Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>Do you accept insurances?</Question>
            <Response>Yes, we are in the panel list of various medical insurance such as<br></br>
             <ul>
                <li>NHIF</li>
                <li>ZAMARA</li>
                <li>MINNET</li>
                <li>AAR</li>
                <li>JUBILEE</li>
             </ul>
            </Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>Do you have a resident medical doctor?</Question>
           
            <Response>Yes, we  do 24 hours a day.</Response>

        </QuestionWrapper>
        <QuestionWrapper>
            <Question>Does your program include medical conditions counseling?</Question>
            <Response>
                <ul>
                    <li>Baseline laboratory investigations are done.</li>
                    <li>Adherence Counselling</li>
                    <li>Medical Assistance/ Diagnosis.</li>
                </ul>
            </Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>Where are you located?</Question>
            <Response>We are located off Kiambu road along Kugeria Road, in ideal and serene environment.</Response>
        </QuestionWrapper>
        <QuestionWrapper>
            <Question>How do I know when I have an Addiction problem?</Question>
            <Response>Screening and Assessment can be done to determine severity of the problem and type and duration of treatment.</Response>
        </QuestionWrapper>

    </Container>
  )
}

export default Faq