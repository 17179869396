import React from 'react'
import styled from 'styled-components'
import { FacebookEmbed } from 'react-social-media-embed';
import {mobile} from './Responsive' 


const Container= styled.div`
    margin-top: 10px;
    margin-left: 60px;
    width: 100vw;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    ${mobile({marginLeft: "20px", margin:"1%"})}
`
const Header= styled.h2``
const PostsContainer= styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`
const Post= styled.div`
    flex-basis: 20%;
    margin: 3%;
    ${mobile({flexBasis: "80%"})}
`

const FacebookPosts = () => {
  return (
    <Container>
        <Header>From Our Socials:</Header>
        <PostsContainer>
            <Post>
            <FacebookEmbed url="https://www.facebook.com/CaretechMedical/posts/pfbid02Y5DhNmRPjhzubafBqVsCJNDe28UUeN5qjff3LtuHMKomCXJqmhqqeM8GCWTTw1rVl" />
            </Post>
            <Post>
            <FacebookEmbed url="https://www.facebook.com/CaretechMedical/posts/pfbid0hoBVeZmjGefrN3j5terdAw75mcwx1tnv6uLUQHUiS8Tsjf3BGUBH9eHQ78b3iPDyl"  />
            </Post>
            <Post>
                <FacebookEmbed url="https://www.facebook.com/CaretechMedical/posts/pfbid051Kq2e1HrmDEcGrgykpeJgLzrJFXicF5VeZiC7gaMVPbuX7qU48jbiHkyCaTq7Pfl"  />
            </Post>
            <Post>
                <FacebookEmbed url="https://www.facebook.com/CaretechMedical/posts/pfbid02CN7k8CyGexwkYxeSSpcN4W1aFgCCQXuQoJ2MCWQpF4EfiStHHQFPj5oX8E377Cfdl"  />
            </Post>
        </PostsContainer>
        
    
    </Container>
  )
}

export default FacebookPosts