import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import SharedLayout from './Components/SharedLayout';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from './Components/AboutUs';
import Partners from './Components/Partners';
import Services from './Components/Services';
import GlobalStyle from './Components/GlobalStyle';
import Faq from './Components/Faq';
import Contacts from './Components/Contacts';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <GlobalStyle/>
    <Routes>
      <Route path='/' element={<SharedLayout/>}>
      <Route index element={<Home/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/partners' element={<Partners/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/faq' element={<Faq/>}/>
      <Route path='/contactus' element={<Contacts/>}/>

      </Route>
    </Routes>
    
  </Router>

  );
}

export default App;
