import React from 'react'
import styled from 'styled-components'

const Container=styled.div`
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100vw;
`
const ServiceWrapper= styled.div`
    flex-basis: 33%;
    display: flex;
    flex-direction: column;
    background-color: teal;
    border-radius: 5%;
    justify-content: center;
    align-items: center;
`
const ServiceImage= styled.img`
    padding: 10px;
    max-width: 100%;
    border-radius: 5%;
`
const serviceName= styled.h3`
    color: white;
    font-weight: 400px;
`

const ServicesCard = () => {
  return (
    <Container>
        <ServiceWrapper>
            <ServiceImage src="assets/imgs/dinning.jpg"/>
            <serviceName>General Consultations</serviceName>
        </ServiceWrapper>
        <ServiceWrapper>
            <ServiceImage src="assets/imgs/dinning.jpg"/>
        </ServiceWrapper>
        <ServiceWrapper>
            <ServiceImage src="assets/imgs/dinning.jpg"/>
        </ServiceWrapper>
    </Container>
  )
}

export default ServicesCard